import type { SortOrder } from 'naive-ui/es/data-table/src/interface';

enum ApiOrderDirections {
  ASC = 'ASC',
  DESC = 'DESC',
}

export default ApiOrderDirections;

export function naiveOrderToApi(order: Omit<SortOrder, 'false'>): ApiOrderDirections {
  return order === 'ascend' ? ApiOrderDirections.ASC : ApiOrderDirections.DESC;
}
