import type { SortOrder } from 'naive-ui/es/data-table/src/interface';
import type { BaseSearchModel } from '../common/dtos/base/base-search-model';

export interface SortParams {
  columnName: string | undefined;
  order: SortOrder | undefined;
}

export interface SearchDto extends BaseSearchModel<string> {
  sortOrder?: SortParams;
}

export const getSearchParams = (filter: Required<SearchDto>): URLSearchParams => {
  const { search, skip, take, sortOrder } = filter;
  const urlParams = new URLSearchParams({
    ...(search && search.length && { search }),
    skip: String(skip),
    take: String(take),
  });
  urlParams.append('columnName', String(sortOrder.columnName));
  urlParams.append('order', naiveOrderToApi(String(sortOrder.order)));
  return urlParams;
};

export function appendUrlParam(url: string, paramName: string, paramValue: string): string {
  const divider = url.includes('?') ? '&' : '?';
  return url.concat(divider, paramName, '=', paramValue);
}
